import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISidebarData, sidebarRoutes, } from "../../shared/constants/sidebar-data";
import { IAuthState } from "../state/app-state";
import { ILoginResponseModel } from "../../models/account";
import { IRoutesModel } from "../../models/routes";

const initialState: IAuthState = {
  id: 0,
  name: "",
  userName: "",
  department: "",
  role: "",
  sessionToken: "",
  permissions: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogin: (state, action: PayloadAction<ILoginResponseModel>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.userName = action.payload.userName;
      state.department = action.payload.department;
      state.role = action.payload.role;
      state.sessionToken = action.payload.sessionToken;
    },

    logout: () => initialState,

    updatePermission: (state, action: PayloadAction<IRoutesModel[]>) => {
      const permissions = action.payload?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
      const newPermission: ISidebarData[] = permissions?.map((route) => {
        const module = sidebarRoutes?.find((x) => x?.module === route?.name);
          return { id: route?.id, ...module };
      });
      state.permissions = newPermission?.filter((x) => !!x?.name) || [];
    },
  },
});

export const { adminLogin, logout, updatePermission } = authSlice.actions;

export default authSlice.reducer;
