import React from "react";
import { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouting from "./routes/AppRouting";
import Spinner from "./layout/Spinner";

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ToastContainer limit={3} className="toaster" hideProgressBar={true} />
        <AppRouting />
      </Suspense>
    </Provider>
  );
};

export default App;
