import React from "react";
import store from "../store/store";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/authSlice";
import { Routing } from "./routing";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const dispatch = useDispatch();
  const state = store?.getState();

  const isAuthenticated = state?.UserData && state?.UserData?.sessionToken ? true : false;
  // const currentRoute = window.location.pathname;

  // const permittedRoutes = state.UserData.permissions.map((menu) => {
  //   const routes = [];
  //   if (menu.route) {
  //     routes.push(menu.route);
  //   }
  //   if (menu.childs && Array.isArray(menu.childs)) {
  //     menu.childs.forEach((childMenu) => {
  //       if (childMenu.route) {
  //         routes.push(childMenu.route);
  //       }
  //     });
  //   }
  //   return routes;
  // }).flat();

  if (!isAuthenticated) {
    dispatch(logout());
    return <Navigate   to={Routing.Login} />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
