import React, { lazy } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Routing } from "./routing";
import ProtectedRoute from "./ProtectedRoute";

const PublicLayout = lazy(() => import("../layout/PublicLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));
const Login = lazy(() => import("../pages/Account/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Users = lazy(() => import("../pages/Users/Users"));
const Upload = lazy(() => import("../pages/Upload/Upload"));
const WeeklyTicketpricing = lazy(() => import("../pages/Upload/WeeklyTicketpricing"));
const Hope = lazy(() => import("../pages/Hope/Hope"));
const ChitChat = lazy(() => import("../pages/ChitChat/ChitChat"));
const ChitChatDetails = lazy(() => import("../pages/ChitChat/ChitChatDetails"));
const CommentsForm = lazy(() => import("../pages/ChitChat/CommentsForm"));
const PollForm = lazy(() => import("../pages/ChitChat/PollForm"));
const Podcast = lazy(() => import("../pages/Podcast/Podcast"));
const PodcastEpisodes = lazy(() => import("../pages/Podcast/PodcastEpisodes"));
const AdminUsers = lazy(() => import("../pages/AdminUsers/AdminUsers"));
const TicketPricingStarter = lazy(() => import("../pages/Ticket-Pricing/TicketPricingStarter"));
const TicketPricingPremium = lazy(() => import("../pages/Ticket-Pricing/TicketPricingPremium"));
const TicketPricingAdvance = lazy(() => import("../pages/Ticket-Pricing/TicketPricingAdvance"));
const Settings = lazy(() => import("../pages/Settings/Settings"));
const RolePermissions = lazy(() => import("../pages/RolePermissions/RolePermissions"));
const RolePermissionForm = lazy(() => import("../pages/RolePermissions/RolePermissionForm"));
const Subscribers = lazy(() => import("../pages/Subscribers/Subscribers"));


const privateRoute = (Element) => {
  return <ProtectedRoute element={<Element />} />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={Routing.Login} />,
  },
  {
    path: Routing.Login,
    element: (
      <PublicLayout>
        <Login />
      </PublicLayout>
    ),
  },
  {
    path: Routing.Dashboard,
    element: <MainLayout>{privateRoute(Dashboard)}</MainLayout>,
  },
  {
    path: Routing.Users,
    element: <MainLayout>{privateRoute(Users)}</MainLayout>,
  },
  {
    path: Routing.Upload,
    element: <MainLayout>{privateRoute(Upload)}</MainLayout>,
  },
  {
    path: Routing.WeeklyTicketPricing,
    element: <MainLayout>{privateRoute(WeeklyTicketpricing)}</MainLayout>,
  },
  {
    path: Routing.Hope,
    element: <MainLayout>{privateRoute(Hope)}</MainLayout>,
  },
  {
    path: Routing.ChitChat,
    element: <MainLayout>{privateRoute(ChitChat)}</MainLayout>,
  },
  {
    path: Routing.ChitChatDetails,
    element: <MainLayout>{privateRoute(ChitChatDetails)}</MainLayout>,
  },
  {
    path: Routing.ChitChatCommentForm,
    element: <MainLayout>{privateRoute(CommentsForm)}</MainLayout>,
  },
  {
    path: Routing.ChitChatPollForm,
    element: <MainLayout>{privateRoute(PollForm)}</MainLayout>,
  },
  {
    path: Routing.Podcast,
    element: <MainLayout>{privateRoute(Podcast)}</MainLayout>,
  },
  {
    path: Routing.PodcastEpisodes,
    element: <MainLayout>{privateRoute(PodcastEpisodes)}</MainLayout>,
  },
  {
    path: Routing.AdminUsers,
    element: <MainLayout>{privateRoute(AdminUsers)}</MainLayout>,
  },
  {
    path: Routing.PricingPlan,
    element: <MainLayout>{privateRoute(TicketPricingStarter)}</MainLayout>,
  },
  {
    path: Routing.TicketPricingStarter,
    element: <MainLayout>{privateRoute(TicketPricingStarter)}</MainLayout>,
  },
  {
    path: Routing.PricingPlan,
    element: <MainLayout>{privateRoute(TicketPricingPremium)}</MainLayout>,
  },
  {
    path: Routing.TicketPricingPremium,
    element: <MainLayout>{privateRoute(TicketPricingPremium)}</MainLayout>,
  },
  {
    path: Routing.PricingPlan,
    element: <MainLayout>{privateRoute(TicketPricingAdvance)}</MainLayout>,
  },
  {
    path: Routing.TicketPricingAdvance,
    element: <MainLayout>{privateRoute(TicketPricingAdvance)}</MainLayout>,
  },
  {
    path: Routing.Settings,
    element: <MainLayout>{privateRoute(Settings)}</MainLayout>,
  },
  {
    path: Routing.RolePermissions,
    element: <MainLayout>{privateRoute(RolePermissions)}</MainLayout>,
  },
  {
    path: Routing.RolePermissionForm,
    element: <MainLayout>{privateRoute(RolePermissionForm)}</MainLayout>,
  },
  {
    path: Routing.Subscribers,
    element: <MainLayout>{privateRoute(Subscribers)}</MainLayout>,
  },
]);

const AppRouting = () => {
  return <RouterProvider router={routes} />;
};

export default AppRouting;
