export enum Modules {
    Dashboard = 'Dashboard',
    Users = 'Users',
    Upload = 'Upload',
    Hope = 'Hope',
    ChitChat = 'ChitChat',
    Podcast = 'Podcast',
    AdminUsers = 'AdminUsers',
    PricingPlan = 'PricingPlan',
    Settings = 'Settings',
    RolePermissions = 'Role Permissions',
    Subscribers = 'Subscribers',
}
