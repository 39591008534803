const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;

export const Routing = {
    Login: `${PATH_PREFIX}/login`,
    Dashboard: `${PATH_PREFIX}/dashboard`,
    Users: `${PATH_PREFIX}/users`,
    Upload: `${PATH_PREFIX}/upload`,
    WeeklyTicketPricing: `${PATH_PREFIX}/upload/weekly-buzz/:id?/ticket`,
    Hope: `${PATH_PREFIX}/hope`,
    ChitChat: `${PATH_PREFIX}/chitchat`,
    ChitChatDetails: `${PATH_PREFIX}/chitchat/:type?/:id?`,
    ChitChatCommentForm: `${PATH_PREFIX}/chitchat/comment/add`,
    ChitChatPollForm: `${PATH_PREFIX}/chitchat/poll/add`,
    Podcast: `${PATH_PREFIX}/podcast`,
    PodcastEpisodes: `${PATH_PREFIX}/podcast/:id?/episodes`,
    AdminUsers: `${PATH_PREFIX}/admin-users`,
    PricingPlan: `${PATH_PREFIX}/pricing-plan`,
    TicketPricingStarter: `${PATH_PREFIX}/pricing-plan/ticket-pricing-starter`,
    TicketPricingPremium: `${PATH_PREFIX}/pricing-plan/ticket-pricing-premium`,
    TicketPricingAdvance: `${PATH_PREFIX}/pricing-plan/ticket-pricing-advance`,
    Settings: `${PATH_PREFIX}/settings`,
    RolePermissions: `${PATH_PREFIX}/role-permissions`,
    RolePermissionForm: `${PATH_PREFIX}/role-permissions/:id?`,
    Subscribers: `${PATH_PREFIX}/subscribers`,
};
