import { Routing } from "../../routes/routing";
import { Modules } from "../enums/modules";

export interface ISidebarData {
  id?: number;
  module: string;
  route: string;
  name: string;
  icon?: string;
  childs?: ISidebarData[];
}

export const sidebarRoutes: ISidebarData[] = [
  {
    module: Modules.Dashboard,
    route: Routing.Dashboard,
    name: "Dashboard",
  },
  {
    module: Modules.Users,
    route: Routing.Users,
    name: "Users",
  },
  {
    module: Modules.Upload,
    route: Routing.Upload,
    name: "Upload",
  },
  {
    module: Modules.Hope,
    route: Routing.Hope,
    name: "Hope",
  },
  {
    module: Modules.ChitChat,
    route: Routing.ChitChat,
    name: "ChitChat",
  },
  {
    module: Modules.Podcast,
    route: Routing.Podcast,
    name: "Podcast",
  },
  {
    module: Modules.AdminUsers,
    route: Routing.AdminUsers,
    name: "Panel User's",
  },
  {
    module: Modules.PricingPlan,
    route: Routing.PricingPlan,
    name: "Pricing Plan",
  },
  {
    module: Modules.Settings,
    route: Routing.Settings,
    name: "Settings",
  },
  {
    module: Modules.RolePermissions,
    route: Routing.RolePermissions,
    name: "Permissions",
  },
  {
    module: Modules.Subscribers,
    route: Routing.Subscribers,
    name: "Subscribers",
  },
];

